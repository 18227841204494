 h2{
    color:#2b9497 ;
    font-weight: bold;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  }
  p{ 
    font-size: 1.1rem;
    line-height: 1.6; 
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
   
  }
  .hero h1 {
    font-size: 5rem;
    line-height: 1 !important;
    font-weight: 600;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    color: #2B9595;
  }
 .color-p{
    color: white;
  }
  .button{
    background-color: #2B9595;
    color: white;   
  }
  
  .hero p {
    font-size: 1.25rem;
    font-weight: 500 !important;
  }  
  .footer-landing {
    background-color: #2b9595;
  }
  .li-footer{
    text-decoration: none;
    color: white;
  }
  /* Footer End */
  
  /* Responsiveness Start */
  @media only screen and (max-width: 768px) {
    .hero h1 {
      font-size: 3rem;
    }
    .hero p {
      font-size: 16px;
    }
    .feature-body{
      text-align: center !important;
    }
  }
  
.card-transparent {
    background-color: rgba(255,255,255,0.8);
    border: none;
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s;
    border-radius: 7%; 
}
.card-transparent1 {
  background-color: rgba(255,255,255,0.8);
  border: none;
  /* box-shadow: 2px 6px 8px rgba(0, 0, 0, 0.1); */
  box-shadow: -6px 8px 18px rgba(0, 0, 0, 0.1);
  
  /* transition: transform 0.3s; */
  border-radius: 7%; 
}

.card-transparent:hover {
    transform: scale(1.05);
    
}

.feature-title{
  color: #2B9595;
}

.about-btn
{

    position: relative;
    transform: translate(0%,0%);
    text-align: center;
    color: #fff;
    font-size: 34px;
    text-transform: uppercase;
    text-decoration: none;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    box-sizing: border-box;
    background: linear-gradient(90deg,#2b9595,#015880);
    background-size: 400%;
    cursor: pointer;
}

.about-btn:hover
{
    animation: animate 8s linear infinite;
}

@keyframes animate
{
    0%
    {
        background-position: 0%;
    }
    100%
    {
        background-position: 400%;
    }
}

.about-btn:before
{
    content: '';
    position: absolute;
    top: -5px;
    left: -5px;
    bottom: -5px;
    right: -5px;
    z-index: -1;
    background: linear-gradient(90deg,#2b9595,#1fbd4f,#0da884,#03a9f4);
    background-size: 200%;
    border-radius: 40px;
    opacity: 0;
    transition: 1s;
}

.about-btn:hover:before
{
    filter: blur(20px);
    opacity: 1;
    animation: animate 8s linear infinite;
}