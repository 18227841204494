:root {
  --primary-color: #2B9595;
  --secondary-color: #28384199;
}

.bgColor-green {
  background-color: #6a5d6b;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.primary-bg {
  background-color: var(--primary-color);
}

.primary-color {
  color: var(--primary-color);
}

.secondary-bg {
  background-color: var(--secondary-color);
}

.login-btn {
  background: var(--peal-black, #2B9595);
  padding: 1rem 3.5rem;
  border-color: unset !important;
  border-radius: 10px;
  color: #fff;
  font-size: 20px;
  line-height: 24px;
  font-weight: 600;
}

.pro-img {
  margin-top: -45px;
  margin-bottom: 20px;
  margin-left: 35px;
}

.little-profile .pro-img img {
  width: 168px;
  height: 168px;
  -webkit-box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.2);
  border-radius: 100%
}

.name-heading {
  text-wrap: nowrap;
  color: #2B9595;
  font-size: 42px;
  font-weight: 700;
}

.name-para {
  color: #7D7D7D;
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
}

.private-toogle-text {
  padding-bottom: 13px;
  color: #000000;
  font-size: 40px;
  font-weight: 500;
  line-height: 48px;
}

.toggle-section {
  margin-top: -1.5rem !important;
}

.about-section {
  color: var(--secondary-color);
  font-size: 16px;
  font-weight: 500;
  line-height: 1.6;
  letter-spacing: 1.2px;
}

.about-text {
  font-size: 22px;
  font-weight: 800;
  color: var(--primary-color);
}

.tab-text .nav-link.active {
  color: #2B9595 !important;
  border: unset !important;
  border-bottom: 4px solid #2B9595 !important;
}

.tab-text .nav-link {
  padding-top: 1rem;
  padding-bottom: 1rem;
  /* border-right: 1px solid green; */
  color: #7D7D7D !important;
  font-size: 18px;
  font-weight: 800;
  line-height: 36px;
  border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.tab-text {
  --bs-nav-tabs-border-radius: unset !important;
  --bs-nav-tabs-border-color: transparent !important;
}

.tab-text .nav-tabs {
  border: unset !important;
}

.date-time {
  color: #65676b;
  font-size: 12px;
  line-height: 12px;
  font-weight: 400;
}

#fill-tab-example-tab-profile {
  border: 2px solid #798080;
  border-top: unset;
  border-bottom: unset;
}

.load-btn:hover {
  background: var(--peal-black, #046868);
}

.load-btn {
  background: var(--peal-black, #2B9595);
  padding: 1rem 2rem;
  border-color: unset !important;
  border-radius: 10px;
  color: #fff;
  font-size: 20px;
  line-height: 24px;
  font-weight: 600;
}

.image-section {
  /* bottom: 0px; */
  margin-top: -25px;
}

.player-wrapper {
  position: relative;
  object-fit: initial;
  /* width: 127px;*/
  height: 300px !important;
  /* Player ratio: 100 / (1280 / 720) */
}

.gen-backdrop.modal-backdrop {
  --bs-backdrop-opacity: 0.95 !important
}

.videos-main {
  position: relative;
  object-fit: initial;
  /* width: 127px;*/
  /* Player ratio: 100 / (1280 / 720) */
}

.react-player__preview {
  background-size: auto !important;
}

.comment-player video {
  object-fit: contain;
}

.react-player__play-icon {
  border-width: 25px 0px 25px 35px !important;
  border-color: transparent transparent transparent #2B9595 !important;
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
}

.video-player {
  position: absolute;
  top: 0;
  left: 0;
}

.video-player video {
  border-radius: 10px;
}

.bg-card {
  background-color: rgb(230, 241, 252) !important;
}

.login-bg {
  background-image: url("/src/assests/Group\ 1000003631.png");
  background-color: #2B9595;
  background-position: bottom;
  background-size: cover;
  min-height: 100vh;
}

.card-shadow {
  border-color: transparent;
  box-shadow: 0px 0px 56px 0px #00260314;
  border-radius: 8px !important;
}

.sign-heading {
  color: #2B9595;
  font-size: 32px;
  font-weight: 600;
  line-height: 38.4px;
}

.login-button {
  background-color: #2B9595;
  border-color: transparent;
  text-align: center;
  color: white;
  border-radius: 5px;
}

.edit-btn {
  background: var(--primary-color);
  border-radius: 10px;
  color: #fff;
  font-size: .875em;
  border-color: unset !important;
}

.input-location input {
  display: flex !important;
  padding: 0.5rem 1.75rem 0.5rem 0.75rem !important;
  border: 0.0625rem solid #d2d6da;
  border-radius: 0.5rem !important;
}

.location-btn {
  padding: 0.5rem 2rem !important;
  background: var(--peal-black, #2B9595);
  border-color: unset !important;
  border-radius: 10px;
  color: #fff;
}

.save-btn {
  padding: 10px 20px;
  font-size: 1em;
}

.publish-btn {
  padding: 10px 20px;
  font-size: 1em;
}

.full-page {
  height: 100%;
  margin: 0;
  padding: 0;
}

/* .slideshow-container {
  height: calc(100% - 120px);
  Subtract the height of your header/footer
  overflow: hidden;
} */

.edit-btn:hover {
  background: var(--peal-black, #046868) !important;
  color: white !important;
}

.edit-btn:active {
  background: var(--peal-black, #046868) !important;
  color: white !important;
}

.login-btn:active {
  background: var(--peal-black, #046868) !important;
  color: black !important;
}

.login-button:active {
  background: var(--peal-black, #046868) !important;
  color: white !important;
}

.avatar {
  height: 34px;
  width: 34px;
}

.modal-blur {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  /* Adjust the transparency as needed */
  backdrop-filter: blur(8px);
  /* Adjust the blur radius as needed */
}

.avatar img {

  border-radius: 60px;
}

.load-btn:active {
  background: var(--peal-black, #046868) !important;
  color: black !important;
}

.location-btn:active {
  background: var(--peal-black, #046868) !important;
  color: black !important;
}

.login-btn:hover {
  background: var(--peal-black, #046868);
}

.flower-button {
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
}

.login-button:hover {
  background: var(--peal-black, #046868);
  border-color: unset;
}

.loader-block {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: auto 0;
  transform: translate(-50%, -50%);
}

.loader {
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid var(--primary-color);
  width: 100px;
  height: 100px;
  -webkit-animation: spin 2s linear infinite;
  /* Safari */
  animation: spin 2s linear infinite;
}

.loader-small {
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid var(--primary-color);
  width: 70px;
  height: 70px;
  -webkit-animation: spin 2s linear infinite;
  /* Safari */
  animation: spin 2s linear infinite;
}




@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}


.share-btn:hover {
  background: var(--peal-black, #046868);
  border-color: unset;
}

.otp-input input {
  width: 100% !important;
  height: 40px !important;
  margin-right: 1em;
}

.pass-input {
  color: #1B1B1B;
  font-size: 20px;
  font-weight: 500;
  line-height: 26px;
}

.logo-img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.navbar-brand {
  height: 50px;
  width: 170px;
}

.min-video-size {
  width: 258px;
  height: 258px;
  border-radius: 10px;
}

.gallery-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.gallery-audio {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.navbar-toggler:focus {
  box-shadow: none !important;
}

.gallery-img video {
  object-fit: cover;
}

.navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgb(255, 252, 255)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='4' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.comment-img-size {
  width: 100%;
  height: 518px;
  overflow: hidden;
  border-radius: 10px;
}

.comment-card {
  border-radius: 18px;
  background-color: #F0F2F5;
}

.link-see {
  /* color: #0d6efd; */
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  text-decoration: underline;
  cursor: pointer;
}

.min-img-size {
  width: 100%;
  height: 235px;
  overflow: hidden;
  border-radius: 10px;
  transition: 0.3s all;
  cursor: pointer;
}

.min-img-size:hover {
  transform: scale(1.03);
}

.min-audio-size {
  width: 100%;
  height: 95px;
  overflow: hidden;
}

.camera-icon {
  width: 43px;
  position: absolute;
  bottom: 16px;
  left: 146px;
}

.card-img-top {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.hero-img {
  width: 100%;
  height: 463px;
  overflow: hidden;
  border-radius: 0 0 25px 25px;
}

.camera-icon-cover {
  right: 0;
  margin-top: -71px;
  margin-right: 30px;

}

.location-btn:hover {
  background: var(--peal-black, #046868) !important;

}

.ar-width {
  width: 80%;
}

.upload-icon {
  cursor: pointer;
}

.copy {
  width: 42px;
}

.img-size-share {
  width: 50px;
}

.fab-container {
  bottom: 5px !important;
  right: 5px !important;
  z-index: 2;
}

.canvas-btn:first-child:active {
  color: unset !important;
  background: unset;
}

.modal-crop {
  height: 600px;
}

.comment-position {
  position: absolute;
}

.canvas-btn:hover {
  color: unset !important;
  background-color: transparent;
}

.custom-width {
  width: 70% !important;
}

.pink-hamburger {
  color: pink;
}

.fade-offcanvas .offcanvas {
  transition: opacity 0.5s ease-in-out !important;
}

@media screen and (max-width: 768px) {

  .toggle-section {
    margin-top: unset !important;
  }

  .comment-position {
    position: relative;
  }

  .card-front {
    scale: 0.6;
    transform-origin: top;
    margin-bottom: -400px;

  }

  .card-back {
    scale: 0.6;
  }

  .ar-width {
    width: auto;
  }

  .modal-crop {
    height: 400px;
  }

  .banner-p-0 {
    padding: unset;
  }

  .buttons-center {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .location {
    flex-direction: column;
    align-items: flex-start !important;
  }

  .load-btn {
    padding: 5px 10px;
  }

  .fab-container {
    right: 0vw !important;
    /* bottom: 7.5vh !important; */
  }

  .location-btn {
    padding: 6px !important;
    font-size: 16px;
  }

  .hero-img {
    height: 200px;
  }

  .camera-icon {
    top: 54px;
    left: 286px;
  }

  .upload-icon {
    width: 35px;
  }

  .min-video-size {
    width: 100%;
    height: 165px;
  }

  .comment-img-size {
    width: 100%;
    height: 320px !important;
    overflow: hidden;
    border-radius: 10px;
  }

  .react-player {
    height: 165px !important;
    border-radius: 10px;
  }

  .react-player video {
    border-radius: 10px;
  }

  .video-player {
    height: 300px !important;
    border-radius: 10px;
  }


  .video-player__play-icon {
    border-width: 25px 0px 25px 35px !important;
    border-color: transparent transparent transparent #2B9595 !important;
  }



  .nav-link:focus-visible {
    box-shadow: none !important;
  }

  .nav-tabs .nav-link:focus {
    /* border-color: white !important; */
    border-top-color: white !important;
    border-right-color: white !important;
    border-left-color: white !important;
  }

  .min-img-size {
    width: 100%;
    height: 165px;
  }

  .login-btn {
    padding: 0.7rem 2rem;
  }

  .little-profile .pro-img img {
    width: 150px;
    height: 150px;
  }

  .img-layout {
    flex-direction: column;
    align-items: center;
  }

  .date-div {
    justify-content: center;
  }

  .pro-img {
    margin-top: -50px;
    margin-left: unset;
  }

  .name-heading {
    font-size: 35px;
    line-height: 30px;
  }

  .name-para {
    padding-top: 5px;
    font-size: 14px;
  }

  .private-toogle-text {
    font-size: 20px;
    line-height: unset;
  }

  .about-section {
    font-size: 14px;
    /* line-height: 30px; */
    line-height: 1.6;
    letter-spacing: 1.2px;
  }

  .img-size-share {
    width: 60px;
  }

  .image-section {
    margin-top: -20px;
  }

  .tab-text .nav-link.active {
    font-size: 14px;
    line-height: 10px;
    border-bottom: 2px solid #2B9595 !important;
    padding-left: 4px;
    padding-right: 4px;
  }

  .tab-text .nav-link {
    font-size: 14px;
    line-height: 10px;
    font-weight: 700;
    padding-left: 4px;
    padding-right: 4px;
  }
}

@media screen and (max-width: 992px) {
  .card-cover {
    scale: 0.6;
    transform-origin: top;
    margin-bottom: -400px;
  }
}



@media screen and (max-width: 400px) {
  .name-heading {
    font-size: 28px;
  }

  .tab-text .nav-link {
    font-size: 14px;
  }

  .tab-text .nav-link.active {
    font-size: 14px;
  }

}

@media screen and (max-width: 550px) {
  .camera-icon {
    position: absolute;
    top: 0;
    right: unset;
    left: unset;
    margin-top: 60px;
    margin-left: 124px;
  }

  .hero-img {
    border-radius: unset;
  }

  /* .navbar-brand {
    width: 50%;
  } */
}

@media screen and (max-width: 375px) {
  .location-btn {
    margin-left: unset !important;
    margin-right: unset !important;
    padding: 8px 8px 8px 8px !important;
    font-size: 12px;
  }

  .input-location input {
    padding: 0.3rem 0rem 0.3rem 1rem !important;
  }

  small {
    font-size: .65rem;
  }

  .edit-btn {
    font-size: .75rem;
    padding: 0.3rem 0.7rem 0.3rem 0.7rem !important;
  }

  .card-body {
    font-size: 18px;
  }

  .navbar-brand {
    height: 40px;
    width: 50%;
    object-fit: contain;
    /* padding-top: 1rem; */
  }

  .avatar {
    height: 30px;
    width: 30px;
  }
}

.delete-btn {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding: 3px;
  border: 0;
  background-color: rgb(228, 38, 38);
  display: none;
  transition: ease-in-out;
  transition-delay: 0.5s;
}

.remove-btn {
  position: absolute;
  right: 5%;
  top: 5%;
  padding: 3px;
  border: 0;
  background-color: rgb(228, 38, 38);
  display: block;
}

.remove-btn-dropdown {
  text-decoration: none;
  position: absolute;
  right: 5%;
  top: 5%;
  padding: unset !important;
  border: 0;
  /* background-color: rgb(228, 38, 38); */
  background-color: #fff;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  display: block;
}

.remove-btn-dropdown button {
  display: inline;
  padding: unset;
}

.remove-btn-dropdown button::after {
  display: none !important;
}

.share-btn {
  position: absolute;
  right: 5%;
  top: 5%;
  padding: 3px;
  border: 0;
  background-color: #2B9595;
  display: block;
}

.eye-btn {
  /* background-color: transparent; */
  background-color: #2B9595;
  position: absolute;
  left: 5%;
  top: 5%;
  padding: 3px;
  border: 0;
  /* background-color: rgb(228, 38, 38); */
  display: block;
}

.audio-btn {
  position: absolute;
  left: 1%;
  bottom: 5%;
  padding: 2px;
  border: 0;
  background-color: rgb(228, 38, 38);
  display: block;
}

.badge-notify {
  background: linear-gradient(90deg, #aa7be9 0%, #9e5cf2 100%);
  position: absolute;
  top: 2px !important;
  right: 5px;
  font-size: 10px !important;
  border-radius: 60% !important;
  height: 20px;
  width: 20px;
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.customHover {
  /* background-color: #faf7fe; */
  background-color: rgba(43, 148, 151, 0.1);
  font-weight: bold;
  color: var(--primary-color);
}

/* 
.card-item:hover .remove-btn{
  z-index: 5;
  display: block !important;
} */


.delete-btn:hover {
  background-color: rgb(152, 28, 28);

}

.delete-btn:hover .modal-img {
  filter: blur(2px) !important;
  -webkit-filter: blur(2px) !important;
}

.slide-btn {
  background-color: transparent !important;
  color: #046868 !important;
}

.delete-btn:active {
  background-color: black !important;
}

/* .modal-image:hover {

  border-radius: 20px !important;
  overflow: hidden;
  filter: blur(2px);
  -webkit-filter: blur(2px);
} */

.modal-image {
  transition: 0.5s all ease;
}

.modal-img {
  border-radius: 20px !important;
  overflow: hidden;
  transition: 0.5s all ease;

}

.modal-img:hover {
  scale: 1.05;
}


.modal-img:hover .delete-btn {
  z-index: 2;
  display: block !important;
}


/* Template */

/* 1 */
.card-width {
  width: 570px;
  height: 928px;
  /* overflow: hidden; */
}

.tooltip-inner {
  background-color: #046868 !important;
}

.bs-tooltip-top .tooltip-arrow::before {
  border-top-color: #046868 !important;
}

.bg-card-temp {
  background-size: cover;
  background-position: center;
  background-image: url(./assests/Wedding\ Invitation\ Card\ of\ Soft\ Watercolor\ Flowers\ Border\ \(1\).png);
}

.bg-card-1 {
  border: 13px solid var(--primary-color);
  border-style: double;
}

.font-cali {
  font-family: "Birthstone Bounce", cursive;
  font-weight: 600;
  font-style: normal;
}

.custom-modal-width {
  max-width: 800px;
}

.comments-container {
  max-height: 300px;
  overflow-y: auto;
  padding-right: 10px;
  scrollbar-width: thin;
}

.comments-container::-webkit-scrollbar {
  width: 3px;
}

.comments-container::-webkit-scrollbar-thumb {
  background-color: #aaa;
  border-radius: 10px;
}

.comment-card {
  background-color: #f0f2f5;
  border-radius: 12px;
}

.comment-card .card-body {
  padding: 8px 12px;
  /* word-break: break-all;
  word-wrap: break-word; */
}


.yellow-color {
  color: #B99058;
  text-shadow: 2px 2px 5px #B99058;
  font-family: 'Times New Roman';
  letter-spacing: 5px;
}

.memory-descr {
  font-weight: 400;
  width: 13rem;
}

.memory-width {
  width: 22rem;
}

.memory-descr-big {
  width: 18rem;
}

.outer-div-img {
  width: 230px;
  height: 320px;
  border: 5px solid #B99058;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  margin: auto;
}

/* .tem2-outer {
  border: unset !important;
  filter: drop-shadow(5px 5px 10px #4444dd);
} */

.Image {
  /* width: 100%; */
  height: 100%;
  object-fit: cover;
}

.edit-btn:disabled {
  background-color: #a69fa6 !important;
}

.temp-font {
  font-family: 'Times New Roman';
}

/* Add this CSS to your project's stylesheet */

.sidebar {
  background-color: #a69fa6;

  /* Light background color for the sidebar */
  padding: 20px;
  /* border-radius: 16px; */
}

.main-content {
  background-color: #f3efef;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  /* display: flex; */
  justify-content: center;
  gap: 10px;
}

.card-container {
  margin-bottom: 20px;
}

.camera-icon-temp {
  position: absolute;
  bottom: 28px;
  right: 168px;
}

.border-left {
  border-left: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}


.dropdown-menu-animated {
  display: block;
  animation: growDown 300ms ease-in-out forwards;
  transform-origin: top center;
}

@keyframes growDown {
  0% {
    transform: scaleY(0);
  }

  80% {
    transform: scaleY(1.1);
  }

  100% {
    transform: scaleY(1);
  }
}

/* Additional styles for the dropdown */
.profile-dropdown .dropdown-toggle {
  display: flex;
  align-items: center;
  justify-content: center;
}

.profile-dropdown .dropdown-menu {
  display: none;
}

.profile-dropdown.show .dropdown-menu {
  display: block;
}

.profile-dropdown .dropdown-item {
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  padding-top: 10px;
  padding-bottom: 10px;
}

.dropdown-menu-animated {
  padding-bottom: 0px !important;
  padding-top: 0px !important;
  overflow: hidden;
}

.dropdown-item {
  font-weight: 500;
}

.pro-header-image {
  -webkit-box-shadow: 1px 1px 3px 0px rgba(0, 0, 0, 1);
  -moz-box-shadow: 1px 1px 3px 0px rgba(0, 0, 0, 1);
  box-shadow: 1px 1px 3px 0px rgba(0, 0, 0, 1);

}