.nav-pills .nav-link.active, .nav-pills .show>.nav-link{
    background-color:rgba(43, 148, 151, 0.1);
  }

  .nav-pills .nav-link.active, .nav-pills .show>.nav-link{
    color: #2B9497;
  }
  .nav-link{
    font-weight: 600;
    color: black ;
  }
  .pro-pic{
    width: 100px;
    height: 100px;
    border-radius: 50%;
  }
  .prof-item{
    background: linear-gradient(263deg, rgba(18,118,240,1) 14%, rgba(14,156,191,1) 100%);
  }

.public li::before {
  content: '•'; 
  margin-right: 8px; 
  font-size: 25px; 
  color: red; 
}
.private li::before {
  content: '•'; 
  margin-right: 8px; 
  font-size: 25px; 
  color: greenyellow; 
}
  .privacy-bullet{
    list-style-type: none !important;
  }
  .prof-detail ul{
    padding-left: 0px !important;
  }
  .prof-btn{
    border-radius: 20px;
    background-color:white;
    color: #1276F0;
    border: 0px;
    font-weight: 600;
    padding-left: 20px;
    padding-right: 20px;

  }
  .btn-div{
    position: absolute;
    top: 10px;
    right: 10px;
  }
  .dash-tabs{
    max-height: 700px;
    min-height: 400px;
    overflow: auto;
  }
  .see-btn{
    background: linear-gradient(90deg, rgba(18,118,240,1) 14%, rgba(14,156,191,1) 100%);
    --bs-btn-border-color:transparent;
    color: white !important ;
  }
  .see-btn:hover{
    background: #2B9497 !important;

    color: white !important ;
  }
  .outlined{
    --bs-btn-color: #006BA3 !important;
    --bs-btn-border-color:#006BA3 !important;
    --bs-btn-hover-bg: #006BA3 !important
  }

  @media screen and (min-width: 1024px) {
    .settings{
      position: absolute;
      bottom: 20px;
    }
    .dash-body{
      height: 700px !important;
    }
    .current{
      width: 50% !important;
    }
    
  }
  .dash-modal .btn-close {
    background-image: url("../../assests/close-circle.png");
    opacity: 1;
    background-size: 100%;
  }
  

  .profile-dropdown .dropdown-toggle::after{
    display: none !important;
  }


.dash-tabs::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: #F5F5F5;
}

.dash-tabs::-webkit-scrollbar
{
	width: 10px;
	background-color: #F5F5F5;
}

.dash-tabs::-webkit-scrollbar-thumb
{
	background-color:#2B9595;
}