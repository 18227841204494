.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

/* .each-slide {
  display: flex;
  width: 100%;
  height: 600px;
}

.each-slide>div {
  width: 100%;
}

.each-slide>img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.each-slide p {
  width: 25%;
  font-size: 1em;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  margin: 0;
  background: #adceed;
} */
.slide-img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  /* border: 2px solid #61dafb; */
}

.rounded-shadow {
  border-radius: 50%; 
  box-shadow: 0 2px 7px rgba(0, 0, 0, 0.2);
}

.each-slide {
  width: 100%;
  height: 600px;
  overflow: hidden;
}

.dash-tabs{
  min-height: 500px;
}
/* .dash-modal{
  min-width: 800px;
} */

